<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line">
        <div class="link__top">
          <a class="link__back" @click="$router.go(-1)">
            <span class="link__back--img">
              <img src="../../../assets/img/arrow-back.svg" />
            </span>
            Назад
          </a>
        </div>
      </div>
      <div class="section__bg section--table section__line--table">
        <div class="row">
          <div class="col-lg-4 col__padding">
            <p>№ {{ contract.id }}</p>
            <p v-if="contract.type">{{ contract.type.title }}</p>
          </div>

          <!-- <div class="col-lg-4 col__padding">
            <button type="submit" class="form__button" v-if="contract.stage">
              {{ contract.stage.title }}
            </button>
          </div> -->
        </div>

        <div class="row mt-10">
          <Comments
            v-if="contract"
            :commentTypeId="$parent.commentTypeId"
            :messageType="$parent.commentType"
            :contract="contract"
            :attachment="$parent.attachment"
            :type="'admin'"
          />
          <div
            class="word__contract col-lg-8"
            id="word__contract"
            v-html="document"
          ></div>
        </div>

        <div class="row mt-10">
          <div v-if="contract.attachments && contract.attachments.length == 0">
            <button type="submit" class="sign__button mr-2" @click="openAttachment()">
              <i class="mdi mdi-check-circle-outline mr-1"></i>
              Заполнить приложение
            </button>
          </div>

          <div class="contract__bottom__info item__column mr-2">
            <div
              type="submit"
              class="item__row item__ac pointer"
              @click="showDocument(contract.id)"
              v-if="contract.attachments && contract.attachments.length != 0"
            >
              <i class="mdi mdi-eye mr-2"></i>

              <p class="mb-0">Посмотреть договор</p>
            </div>

            <div v-if="contract.stage">{{ contract.stage.title }}</div>

            <button
              type="submit"
              class="sign__button mr-2"
              v-if="contract.stage && contract.stage.name == 'signing_kisc'"
              @click="handleSend()"
            >
              <i class="mdi mdi-check-circle-outline mr-1"></i>
              Подписать
            </button>

            <v-menu
              v-model="selectedStatus"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              content-class="statuses__toggle"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="col__padding" v-bind="attrs" v-on="on">
                  <button type="submit" class="sign__button mr-2">
                    <i class="mdi mdi-share-variant-outline mr-2"></i>
                    Изменить статус
                  </button>
                </div>
              </template>

              <div class="column statuses__items">
                <div
                  class="statuses__item"
                  v-for="item in statuses"
                  :key="item.id"
                  @click="setStatus(item.id)"
                >
                  <p>
                    {{ item.title }}
                  </p>
                </div>
              </div>
            </v-menu>
          </div>

          <div
            class="contract__bottom__info item__column mr-2"
            v-for="(attach, attach_index) in contract.attachments"
            :key="attach.id"
          >
            <div class="item__column mb-2">
              <div
                class="item__row item__ac pointer"
                @click="showAttach(attach, attach.id)"
              >
                <i class="mdi mdi-eye mr-2"></i>
                <p class="mb-0">Посмотреть приложение №{{ attach_index + 1 }}</p>
              </div>

              <div v-if="attach.stage">{{ attach.stage.title }}</div>

              <div v-if="attach.stage && attach.stage.name == 'signing_kisc'">
                <button
                  class="sign__button pointer"
                  @click="handleSend(attach_index, 'attachment')"
                >
                  <i class="mdi mdi-check-circle-outline mr-1"></i>
                  Подписать
                </button>
              </div>

              <div class="item__row item__ac pointer" @click="openAttachment()">
                <i class="mdi mdi-pencil mr-2"></i>
                Редактировать
              </div>

              <v-menu
                v-model="attach.selectedStatus"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                content-class="statuses__toggle"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="col__padding" v-bind="attrs" v-on="on">
                    <button type="submit" class="sign__button mr-2">
                      <i class="mdi mdi-share-variant-outline mr-2"></i>
                      Изменить статус
                    </button>
                  </div>
                </template>

                <div class="item__column statuses__items">
                  <div
                    class="statuses__item"
                    v-for="item in statuses"
                    :key="item.id"
                    @click="setAttachmentStatus(item.id, attach.id)"
                  >
                    <p>
                      {{ item.title }}
                    </p>
                  </div>
                </div>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HtmlToDocx from "html-docx";
import Comments from "../../../components/Comments";

import { mapGetters } from "vuex";
export default {
  components: {
    Comments,
  },
  data() {
    return {
      statuses: [],
      comment: "",
      comments: [],
      dialogStatus: false,
      selectedStatus: false,
      contract: [],
      document: "",
      ready: "",
      esp__array: [],
    };
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;

      if (typeof this.esp__array !== "undefined" && this.esp__array.code == 200) {
        this.signDocument(this.esp__array.responseObject, this.typeSign);
      }
    };
  },
  methods: {
    signDocument(cms, type) {
      let url =
        "admin/documents/" + this.contract.type.name + "/" + this.contract.id + "/sign";
      if (type == "attachment") {
        url =
          "admin/documents/" +
          this.contract.type.name +
          "/" +
          this.contract.id +
          "/" +
          this.currentAttachIndex +
          "/sign";
      }
      this.$axios({
        method: "patch",
        url: this.$API_URL + this.$API_VERSION + url,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          document_cms: cms,
          document_id: this.contract.id,
          attachment_id: this.currentAttachIndex,
        },
      })
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Документ успешно подписан",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.showDocument(this.contract.id);
          this.getDocument(this.contract.id);
        })
        .catch((error) => {
          console.warn(error);
          this.$toast.open({
            message: "Невалидный эцп",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
    setAttachmentStatus(id, attach_id) {
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "admin/set/attachment/status",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          id: attach_id,
          status: id,
        },
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });

          this.getDocument(this.contract.id);
          this.selectedStatus = false;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    openAttachment() {
      if (this.contract.attachments[0]) {
        this.$router.push(
          "/admin/sobs/attach/" +
            this.contract.id +
            "?data=" +
            this.contract.attachments[0].fields.admin_system_name
        );
      } else {
        this.$router.push("/admin/sobs/attach/" + this.contract.id);
      }
      // this.$router.push('/admin/sobs/attach/'+contract.id);
    },
    deleteComment(id) {
      this.$axios({
        method: "delete",
        url: this.$API_URL + this.$API_VERSION + "delete/comment",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          id: id,
        },
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.getComments(this.contract.id);
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    convertDate(date) {
      if (date) {
        let d = date.split("T")[0];
        return d.split("-")[2] + "." + d.split("-")[1] + "." + d.split("-")[0];
      }
    },
    setStatus(id) {
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "admin/set/contract/status",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        data: {
          id: this.contract.id,
          status: id,
        },
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.getDocument(this.contract.id);
          this.selectedStatus = false;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    exportWord() {
      HtmlToDocx({
        exportElement: "#word__contract", // 需要转换为word的html标签
        exportFileName: "list.docx", // 转换之后word文档的文件名称
        StringStyle: "", // css样式以字符串的形式插入进去
        margins: {
          top: 1440,
          right: 1440,
          bottom: 1440,
          left: 1440,
          header: 720,
          footer: 720,
        }, // word的边距配置
      });
    },

    getComments(id) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "get/comments?doc_id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.comments = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    documentStatuses() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "documents/statuses",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.statuses = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },

    handleSend(id, type) {
      let folder = this.contract.base_64;
      if (type == "attachment") {
        this.typeSign = "attachment";
        folder = this.contract.attachments[id].base_64;
        this.currentAttachIndex = this.contract.attachments[id].id;
      }
      if (this.ready == false) {
        this.showError = true;
      } else {
        this.showError = false;
        var createCAdESFromBase64Hash = {
          module: "kz.gov.pki.knca.commonUtils",
          method: "createCAdESFromBase64",
          args: ["PKCS12", "SIGNATURE", folder, true],
        };
        this.connection.send(JSON.stringify(createCAdESFromBase64Hash));
      }
    },

    getDocument(id) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "admin/document?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.contract = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    showDocument(id) {
      this.$parent.commentType = "contract";
      this.$parent.commentTypeId = id;
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "admin/documents/sobs/show?id=" + id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    showAttach(attachment, id) {
      this.$parent.commentType = "attachment";
      this.$parent.commentTypeId = id;
      this.$parent.attachment = attachment;
      this.$axios({
        method: "get",
        url:
          this.$API_URL +
          this.$API_VERSION +
          "admin/documents/sobs/attachment/show?id=" +
          id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document = response.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.documentStatuses();
    this.getDocument(this.$route.params.id);
    this.showDocument(this.$route.params.id);
    this.getComments(this.$route.params.id);
  },
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>

<style>
.word__contract {
  width: 873px;
  height: 900px;
  overflow-y: auto;
}
.statuses__toggle {
  min-width: 270px;
}
.statuses__items {
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
}
.statuses__item {
  cursor: pointer;
}
.statuses__item:hover {
  color: #2a79f0;
}
</style>
